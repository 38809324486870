export const getVersion = () => {
    const version = process.env.REACT_APP_VERSION;
    if (version == null) {
        console.warn(
            'Version is not added to environment. Add REACT_APP_VERSION to .env'
        );
        return '0.0.1';
    }
    return version;
};

export const getAppName = () => {
    const name = process.env.REACT_APP_NAME;
    if (name == null) {
        console.warn(
            'App name is not added to environment. Add REACT_APP_NAME to .env'
        );
        return 'app';
    }
    return name;
};

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isCordovaApp = () => window.cordova != null;
export const isCordovaiOSApp = () =>
    isCordovaApp() && window.cordova.platformId === 'ios';
