import React, { Suspense } from 'react';
import './assets/index.css';
import './assets/fonts.css';
import 'normalize.css';
import { isCordovaApp, isCordovaiOSApp } from './services/env';

declare global {
    interface Window {
        cordovaFetch?(
            input: RequestInfo,
            init?: RequestInit
        ): Promise<Response>;
    }
}

const renderApp = async () => {
    // @see https://github.com/apache/cordova-ios/issues/897
    if (isCordovaiOSApp() && window.cordovaFetch) {
        window.fetch = window.cordovaFetch;
    }

    const ReactDOM = await import('react-dom');
    const Provider = React.lazy(() => import('./components/Provider'));
    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={null}>
                <Provider />
            </Suspense>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (isCordovaApp()) {
    // Ensure cordova is ready, if we are running the app
    document.addEventListener('deviceready', renderApp);
} else {
    renderApp();
}
